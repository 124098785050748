<template>
  <div>
    <div class="page-wrapper-xl">
      <div class="nexcard p-4">
        <h3 class="modal-card__title text-center mb-4">
          {{ "Trial Phase" }}
        </h3>

        <div class="step__row" style="max-width: 300px">
          <div class="step__col-auto" id="generalDetailsIcon">
            <img src="@/assets/input-step-icons/icon-step-complete.svg" />
            <span class="step__name">{{ "Phase 1" }}</span>
          </div>
          <hr />
          <div class="step__col-auto" id="letterDetailsIcon">
            <img src="@/assets/input-step-icons/icon-step-complete.svg" />
            <span class="step__name">{{ "Phase 2" }}</span>
          </div>
        </div>

        <div class="px-5 py-5">
          <div class="card p-3">
            <h5 class="mb-0">
              <b>{{ "Upload Jury Instruction Document" }}</b>
            </h5>
            <small class="text-start">
              (if applicable, else proceed without the document)
            </small>

            <div class="row mt-4 mb-3">
              <div class="col">
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  ref="file_Judgement"
                  accept=".pdf"
                  @change="handleFileChange"
                />
                <small class="font-12 mx-1">
                  <b-icon icon="info-circle" />
                  Please upload PDF file only
                </small>
              </div>

              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  id="fileName"
                  placeholder="File Name"
                  v-model="selectedFileName"
                />
                <small class="font-12 mx-1">
                  <b-icon icon="info-circle" />
                  Please rename your file
                </small>
              </div>
            </div>

            <div>
              <button
                @click="handleFileUpload"
                id="buttonFile"
                class="btn btn-primary float-right"
              >
                <div class="row">
                  <div class="col-1">
                    <span class="material-icons-outlined">file_upload</span>
                  </div>
                  <div class="col">
                    {{ "Upload File" }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="isWitnessClientEmpty && isWitnessOpposingEmpty"
          class="mx-5">
          <p class="text-center text-danger mb-2">
            <b>There is a lack of available information regarding<br>
            the witnesses associated with the client or any opposing witnesses.</b>
          </p>
          <p class="text-center text-danger">
            <b>Therefore, Jury instruction is necessary to get Trial Phase documents.</b>
          </p>
        </div>
        <div v-else-if="!file_in_upload" style="height: 2.5rem;">
          <button
            @click="noFileUpload"
            id="buttonFile"
            class="btn btn-primary float-right d-flex align-items-center"
          >
            <span>Proceed without uploading Jury Instruction</span>
            <span class="material-symbols-outlined text-start font-18 ms-2">
              arrow_forward_ios
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import LegalExpertSystem from "@/store/LES.js";
export default {
  data() {
    return {
      file_in_upload: false,
      selectedFile: null,
      selectedFileName: "",
      isWitnessClientEmpty: false,
      isWitnessOpposingEmpty: false,
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.selectedFileName = file.name;
      } else {
        this.selectedFile = null;
        this.selectedFileName = "";
      }
    },

    handleFileUpload() {
      this.file_in_upload = true;

      if (
        this.selectedFile == null ||
        this.selectedFile?.type !== "application/pdf"
      ) {
        this.$toast.error("Please Upload a PDF file");
      } else {
        var fileNameWithoutExtension =
          this.selectedFileName.replace(".pdf","");
        // console.log("Name:", this.selectedFileName);
        // console.log("Name without extension:", fileNameWithoutExtension);

        var bodyFormData = new FormData();
        bodyFormData.append("case_file", this.selectedFile);
        bodyFormData.append("file_name", fileNameWithoutExtension);
        // console.log("upload", bodyFormData);

        this.pageLoading = true;

        LegalExpertSystem.sendFile(this.$route.params.id, bodyFormData)
          .then((response) => {
            this.$toast.success(response.data.message);
            this.$router.push({
              name: "LegalExpertSystemPreTrialResult",
              params: {
                id: this.$route.params.id,
                category: "Trial",
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.$refs.file_Judgement.value = null; // Clear the file input
      this.selectedFileName = "";
    },

    noFileUpload() {
      // var bodyFormData = new FormData();
      // bodyFormData.append("case_file", null);
      // bodyFormData.append("file_name", null);
      this.pageLoading = true;
      LegalExpertSystem.sendFile(this.$route.params.id, null)
        .then((response) => {
          this.$toast.success(response.data.message);
          this.$router.push({
            name: "LegalExpertSystemPreTrialResult",
            params: {
              id: this.$route.params.id,
              category: "Trial",
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.isWitnessClientEmpty = JSON.parse(
      localStorage.getItem("isWitnessClientEmpty")
    );
    this.isWitnessOpposingEmpty = JSON.parse(
      localStorage.getItem("isWitnessOpposingEmpty")
    );
  },
};
</script>
<style>
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* padding: 1.5rem; */
}

hr {
  width: 60px;
  margin: 1rem 0.5rem 1rem 0.5rem;
}
.step__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}
.step__col {
  min-width: 20px;
  text-align: start;
  user-select: none;
}

.step__name {
  white-space: pre;
  margin-left: 0.5rem;
}
</style>
